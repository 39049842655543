import React from "react";
import CV from "../images/CV.pdf";

const downloadPdf = async () => {
  try {
    const response = await fetch(CV);
    const pdfContent = await response.blob();

    const url = URL.createObjectURL(pdfContent);

    const a = document.createElement("a");
    a.href = url;
    a.download = "CV_Ahmad_Fauzi.pdf";
    a.click();

    // Cleanup
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading PDF:", error);
  }
};

const DownloadButton = () => {
  return (
    <button
      onClick={downloadPdf}
      className="font-primary font-semibold mt-4 text-sm px-5 py-2 rounded focus:outline-none whitespace-nowrap bg-slate-150 text-slate-800 hover:bg-slate-300"
    >
      Download CV
    </button>
  );
};

export default DownloadButton;
