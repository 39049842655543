import React from "react";

const LetTalk = () => {
  const openGmailCompose = () => {
    window.open(
      "https://mail.google.com/mail/?view=cm&fs=1&to=achmadfauzy464@gmail.com&su=Let's%20Talk&body=Hello,%20let's%20start%20a%20conversation!",
      "_blank"
    );
  };

  return (
    <button
      onClick={openGmailCompose}
      className="font-primary font-semibold text-sm px-5 py-2 rounded focus:outline-none whitespace-nowrap bg-slate-150 text-slate-800 hover:bg-slate-300"
    >
      Let's Talk
    </button>
  );
};

export default LetTalk;
