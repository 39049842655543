import React, { useState, useEffect, useRef } from "react";

function Modal({ isOpen, onClose, cardDetails }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const modalRef = useRef();

  useEffect(() => {
    let interval;

    if (
      isOpen &&
      cardDetails &&
      Array.isArray(cardDetails.image) &&
      cardDetails.image.length > 1
    ) {
      interval = setInterval(() => {
        setCurrentImageIndex(
          (prevIndex) => (prevIndex + 1) % cardDetails.image.length
        );
      }, 3000);
    }

    return () => clearInterval(interval);
  }, [isOpen, cardDetails]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  const modalStyle = isOpen ? "block" : "hidden";
  const backdropStyle = isOpen
    ? "fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm"
    : "";

  if (
    !isOpen ||
    !cardDetails ||
    !cardDetails.image ||
    cardDetails.image.length === 0
  ) {
    return null;
  }

  const handleEllipsisClick = (index) => {
    setCurrentImageIndex(index);
  };

  const renderEllipsisIcons = () => {
    return cardDetails.image.map((_, index) => (
      <span
        key={index}
        className={`mx-1 w-2 h-2 rounded-full inline-block cursor-pointer ${
          currentImageIndex === index ? "bg-gray-700" : "bg-gray-300"
        }`}
        onClick={() => handleEllipsisClick(index)}
      ></span>
    ));
  };

  return (
    <div className={backdropStyle}>
      <div
        ref={modalRef}
        className={`fixed inset-0 overflow-y-auto z-50 ${modalStyle}`}
      >
        <div className="flex items-center justify-center min-h-screen p-4">
          <div className="relative bg-white w-full max-w-3xl p-4 rounded-lg">
            <div className="mb-4">
              {cardDetails.image.length > 0 && (
                <img
                  src={cardDetails.image[currentImageIndex]}
                  alt={`Image ${currentImageIndex + 1}`}
                  className="w-full lg:h-96 h-64 object-cover rounded"
                />
              )}
              {cardDetails.image.length > 1 && (
                <div className="flex items-center justify-center mt-2">
                  {renderEllipsisIcons()}
                </div>
              )}
            </div>
            <div className="text-lg font-semibold mb-2">
              {cardDetails.title}
            </div>
            <div className="text-sm text-gray-500">{cardDetails.company}</div>
            <div className="text-sm text-gray-500 mt-4 text-justify">
              {cardDetails.description}
            </div>
            <button
              onClick={onClose}
              className="absolute top-4 shadow-lg right-4 bg-orange-600 px-4 text-center rounded py-1 text-white hover:text-slate-300"
            >
              x
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
