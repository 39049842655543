import React from "react";

function About() {
  return (
    <div className="text-center">
      <div className="lg:hidden sm:block">
        <div className="font-primary text-sm text-slate-800">
          "Sangat termotivasi untuk mengembangkan kemampuan secara profesional.
          Dapat berkomunikasi dengan baik, disiplin, dan bertanggung jawab dalam
          pekerjaan."
        </div>
      </div>
      <div className="hidden lg:block">
        <div className="font-primary text-sm text-slate-800">
          "Sangat termotivasi untuk mengembangkan kemampuan secara profesional.
          Dapat berkomunikasi dengan baik,
        </div>
        <div className="font-primary text-sm text-slate-800">
          disiplin, dan bertanggung jawab dalam pekerjaan."
        </div>
      </div>
    </div>
  );
}

export default About;
